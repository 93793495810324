<script lang="ts" setup>
import type { FetchError } from 'ofetch'
import type { UForm } from '#components'

const $api = useNuxtApp().$api.order
const { voucher, setVoucher, subTotal, items } = useOrder()
const { t } = useI18n()
const { isKredivo } = usePromo()

const state = reactive({
  code: '',
})
const loading = ref(false)
const form = ref<InstanceType<typeof UForm> | null>(null)

function removeVoucher() {
  useSegment().track('Coupon Removed', {
    coupon_id: voucher.value!.code,
    discount: voucher.value!.discount,
  })

  setVoucher(undefined)
  state.code = ''
}

async function applyVoucher() {
  if (state.code === '') {
    form.value!.setErrors([
      {
        path: 'code',
        message: t('form-validation.required'),
      },
    ])
    return
  }

  useSegment().track('Coupon Entered', {
    coupon_id: state.code,
  })

  loading.value = true
  await $api
    .getVoucher(state.code, {
      amount: subTotal.value,
      productIds: items.value!.map((item) => item.product.id),
      warrantyAmount: items.value!.reduce(
        (acc, item) => acc + item.warrantyPrice,
        0,
      ),
    })
    .then((response) => {
      setVoucher({
        code: response.code,
        discount: response.value,
      })

      useSegment().track('Coupon Applied', {
        coupon_id: response.code,
        discount: response.value,
      })
    })
    .catch((error: FetchError) => {
      setVoucher(undefined)

      let message = ''
      if (error.statusCode !== 406) {
        message = t('pages.order.voucher-error.not-found')
      } else {
        switch (error.data.data.error) {
          case 1:
            message = t('pages.order.voucher-error.below-min-spend', [
              error.data.data.min,
            ])
            break
          case 2:
            message = t('pages.order.voucher-error.on-sale')
            break
        }
      }

      form.value!.setErrors([
        {
          path: 'code',
          message,
        },
      ])

      useSegment().track('Coupon Denied', {
        coupon: state.code,
        reason: message,
      })
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  if (voucher.value !== undefined) {
    state.code = voucher.value.code
    applyVoucher()
  }
})
</script>

<template>
  <div
    v-if="voucher !== undefined"
    class="flex items-center justify-between text-xs"
  >
    <p class="flex items-center gap-1">
      {{ voucher.code }}
      <UIcon
        v-if="!isKredivo"
        name="i-far-circle-xmark"
        class="size-3 cursor-pointer text-red-500"
        @click="removeVoucher"
      />
    </p>
    <p>-{{ $n(voucher.discount, 'currency') }}</p>
  </div>
  <UForm v-else ref="form" :state="state" @submit="applyVoucher">
    <UFormGroup name="code" :label="$t('pages.order.voucher-code')">
      <div class="flex">
        <UInput
          v-model="state.code"
          :placeholder="$t('pages.order.voucher-code')"
          required
          :ui="{ rounded: 'rounded-none rounded-l-lg' }"
          class="w-full"
        />
        <UButton
          :label="$t('global.apply')"
          :ui="{ rounded: 'rounded-none rounded-r-lg' }"
          :loading="loading"
          @click="applyVoucher"
        />
      </div>
    </UFormGroup>
  </UForm>
</template>
